import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import { navigate } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';
import { VitlConsultation } from 'vitl-consultation';
import { GraphQLError } from '@src/interfaces/graphql';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
//context
import { GlobalContext } from '@store/global-state';
// services
import {
  trackEvent,
  trackHeapUserDetails,
  trackIdentify,
  trackTikTokUserDetails,
} from '@services/tracking/tracking';
import { getToken, setToken } from '@services/auth';
import { getCookie, removeCookie } from '@services/cookies';
import { useFetch } from '@services/useHooks';
import { COOKIES } from '@constants/cookies';
// queries
import { USER_AUTH_DETAILS, USER_DETAILS, getUserDetails } from '@queries/user';
import { BASKET_MERGE_GUEST } from '@queries/basket';

interface IProps {
  location: any;
}

const ttq: (type: string, extra: object) => void = () => {};

const API_URL = process.env.GATSBY_APP_SYNC_URL;
const API_KEY = process.env.GATSBY_APP_SYNC_KEY;

const Consultation: React.FC<IProps> = ({ location }) => {
  const { setIsLoggedIn } = useContext(GlobalContext);
  const [showConsultation, setShowConsultation] = useState(true);
  const [mergeGuestBasket, {}] = useMutation(BASKET_MERGE_GUEST, {
    onCompleted: () => removeCookie(COOKIES.basketId),
  });

  const closeConsultationOverlay = () => {
    trackEvent('Closed consultation');
    const token = getCookie(COOKIES.token);
    if (token) {
      setIsLoggedIn(true);
    }
    setShowConsultation(false);
  };

  const handleViewResultsCallback = () => {
    trackEvent('Finished consultation');
    ttq('ClickButton', { description: 'Consultation Ended' });
    closeConsultationOverlay();
    setIsLoggedIn(true);
    navigate('/account/result/loading', { replace: true });
  };

  const handleGoToLoginCallback = () => {
    removeCookie(COOKIES.token);
    removeCookie(COOKIES.guestToken);
    closeConsultationOverlay();
    navigate('/account/signin');
  };

  const handleCloseConsultationCallback = () => {
    closeConsultationOverlay();

    const isPreviousPathMagicSignIn = location?.state?.isPreviousPathMagicSignIn;

    if (isPreviousPathMagicSignIn) navigate('/');
    else navigate(-1);
  };

  const handleDeleteUserDataCallback = () => {
    removeCookie(COOKIES.token);
    removeCookie(COOKIES.guestToken);
  };

  const handleGetTokenCallback = () => {
    return getCookie(COOKIES.token);
  };

  const handleGetGuestTokenCallback = () => {
    return getCookie(COOKIES.guestToken);
  };

  const handleSetTokenCallback = (token: string) => {
    return setToken(token);
  };

  const handleIdentifyUserCallback = async () => {
    let userAuthDetailsResponse;
    try {
      userAuthDetailsResponse = await useFetch(USER_AUTH_DETAILS);
    } catch (error) {
      return;
    }
    const customerDataPlatformId =
      userAuthDetailsResponse?.data?.user_authDetails?.customerDataPlatformId;

    if (customerDataPlatformId) {
      trackIdentify(customerDataPlatformId);
    }

    const isThereAToken = getToken();
    const { data } = await useFetch(USER_DETAILS);

    const basketId = getCookie(COOKIES.basketId);
    const isUserLoggedIn = isThereAToken && userAuthDetailsResponse?.data?.user_authDetails;
    const isGuestbasketId = basketId && basketId.toString().length === 32;
    const email = data?.user_details?.email;

    if (!isUserLoggedIn) {
      return;
    }
    if (isGuestbasketId) {
      mergeGuestBasket({ variables: { basketId } });
    }
    if (email) {
      trackHeapUserDetails(email);
      trackTikTokUserDetails(email);
    }
  };

  const handleExitToPregnantCallback = () => {
    closeConsultationOverlay();
    navigate('/coming-soon/pregnancy');
  };

  const handleExitToVeganCallback = () => {
    closeConsultationOverlay();
    navigate('/coming-soon/vegan');
  };

  const handleViewRecommendationsCallback = () => {
    closeConsultationOverlay();
    navigate('/account/dashboard');
  };

  const onErrorCallback = (error: GraphQLError) => {
    const { message, locations, path } = error;
    Sentry.captureException(
      new Error(`[Consultation error]: Path: ${path}, Message: ${message}, Location: ${locations}`),
      {
        extra: {
          message,
          locations,
          path,
        },
      }
    );
  };

  useEffect(() => {
    trackEvent('Started consultation');
    ttq('ViewContent', { description: 'Consultation Started' });
    // if consultation was opened from EO or PP page, set it in storage
    if (location.state?.prevPath) {
      const pathFragments = location.state.prevPath
        .split('/')
        .filter((fragment: string) => fragment !== '');
      const pageName = pathFragments[pathFragments.length - 1];

      if (pageName === 'essential-one' || pageName === 'personalised') {
        sessionStorage.setItem('consultationProduct', pageName);
      }
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="What vitamins should I take? | Vitl vitamin quiz"
        description="Which vitamins are right for you? Take our free vitamin health quiz for personalised vitamins & supplements tailored for you."
      />
      <VitlConsultation
        apiUrl={API_URL}
        apiKey={API_KEY}
        isOverlayOpen={showConsultation}
        viewResultsCallback={handleViewResultsCallback}
        goToLoginCallback={handleGoToLoginCallback}
        closeConsultationCallback={handleCloseConsultationCallback}
        deleteUserDataCallback={handleDeleteUserDataCallback}
        getTokenCallback={handleGetTokenCallback}
        getGuestTokenCallback={handleGetGuestTokenCallback}
        setTokenCallback={handleSetTokenCallback}
        identifyUserCallback={handleIdentifyUserCallback}
        exitToPregnantCallback={handleExitToPregnantCallback}
        exitToVeganCallback={handleExitToVeganCallback}
        viewRecommendationsCallback={handleViewRecommendationsCallback}
        onErrorCallback={onErrorCallback}
      />
    </Layout>
  );
};

export default Consultation;
